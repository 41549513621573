import React, { useEffect, useState, useRef } from "react";
import {useNavigate} from 'react-router-dom';

import MenuAlto from './menuAlto'
import Intestazione from './intestazione'
import TitoloPagina from './TitoloPagina'
import FondoPagina from './fondoPagina'
import ElementoInput from './ElementoInput'
import FormResponsive from './FormResponsive'
import Parametri from './parametri';
import Clessidra from './Clessidra';

import './App.css';

function ProvaGratis(props) {
    var giorni=[];
    for (var g=1; g<=31; g++) giorni.push(g);
    const mesi=["gennaio","febbraio","marzo","aprile","maggio","giugno","luglio","agosto","settembre","ottobre","novembre","dicembre"];

    const comandoNavigazione = useNavigate();

    const [flagAttesa, setFlagAttesa] = useState(true);
    const [flagRegistrato, setFlagRegistrato] = useState(false);
    const [flagEvidenziaRichiestaConsenso, setFlagEvidenziaRichiestaConsenso] = useState(false);
    const [sessionId, setSessionId] = useState("");
    const [email, setEmail] = useState("");
    const [giornoChiusuraEsercizio, setGiornoChiusuraEsercizio] = useState(31);
    const [meseChiusuraEsercizio, setMeseChiusuraEsercizio] = useState(11); // mesi da 0 a 11 attenzione!
    const [flagConsenso, setFlagConsenso] = useState(false);
    const [valoriCampiForm, setValoriCampiForm] = useState({});

    const elencoCampi = [
        { label: 'Tuo numero di telefono:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Tuo cognome:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Tuo nome:', type: 'text', initialWidth: 150, minWidth: 150 },
        { label: 'Nome della società:', type: 'text', initialWidth: 250, minWidth: 250 },
        { label: 'P.IVA della società:', type: 'text', initialWidth: 150, minWidth: 150 },
    ];

    useEffect(() => {
        setSessionId("");
        setFlagConsenso(false);
        setEmail("");
        setValoriCampiForm({});
        preflight();
    },[])

    function vaiLink(link) {
        comandoNavigazione(link);
        window.scrollTo(0, 0);
    }    

    function verificaEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
        return emailRegex.test(email);
    }

    function preflight() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"preflight"})
        };

        fetch(Parametri("serverURL"), requestOptions).then(risposta => risposta.json()).then(
            (valoreDiRitorno) => {
                setFlagAttesa(false);
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                    vaiLink("/")
                } else {
                    setSessionId(valoreDiRitorno.sessionId);
                }
            },
            (error) => {
                setFlagAttesa(false);
                alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                vaiLink("/")
            }
        ) 
    }    

    function inviaDati() {
        if (!flagConsenso) {
            setFlagEvidenziaRichiestaConsenso(true);
            alert("Spunta il consenso al trattamento dati per proseguire, senza quello non possiamo memorizzare i dati che hai inserito");
            return;
        }
        if (!verificaEmail(email)) {
            alert("Verifica l'indirizzo email");
            return;
        }

        setFlagAttesa(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"nuova",
                sessionId:sessionId,
                email:email,
                consenso: flagConsenso?1:0,
                telefono:valoriCampiForm.input_0,
                cognome:valoriCampiForm.input_1,
                nome:valoriCampiForm.input_2,
                societa:valoriCampiForm.input_3,
                piva:valoriCampiForm.input_4,
                giornoChiusuraEsercizio:giornoChiusuraEsercizio,
                meseChiusuraEsercizio:parseInt(meseChiusuraEsercizio)+1
            })
        };

        fetch(Parametri("serverURL"), requestOptions).then(risposta => risposta.json()).then(
            (valoreDiRitorno) => {
                setFlagAttesa(false);
                if (valoreDiRitorno.risultatoOperazione!==1) {
                    alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
                }
                if(valoreDiRitorno.risultatoOperazione===1){
                    setFlagRegistrato(true);
                }
            },
            (error) => {
                setFlagAttesa(false);
                alert("Si è verificato un errore gestendo la richiesta, per favore riprova e se l'errore persiste contattaci all'indirizzo "+props.emailAssistenza);
            }
        ) 
    }
    
    if (flagAttesa) {
        return (<Clessidra top={window.innerHeight/2} loading={true}/>)
    }

    if (flagRegistrato) {
        return (
            <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    cursor:"pointer",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                Grazie per aver richiesto la prova gratuita.
                <br/><br/>
                Riceverai la password per accedere all'indirizzo email che hai indicato: {email}
                <br/><br/>
                <span style={{fontSize:14, fontStyle:"italic"}}>L'attivazione della prova gratuita prevede una verifica manuale dei dati inseriti, pertanto l'invio della password non è immediato anche se normalmente avviene in giornata. Se passano più di due giorni lavorativi senza che tu abbia ricevuto risposta, cortesemente contattaci all'indirizzo {props.emailAssistenza}</span>

                <div 
                    style={{
                        backgroundColor:"#006fe0", 
                        color:'#f0f0f0',                         
                        marginTop:20, 
                        marginBottom:10, 
                        paddingTop:8, 
                        paddingBottom:8, 
                        paddingLeft:20, 
                        paddingRight:20, 
                        cursor:'pointer'
                    }}
                    onClick={() => vaiLink("/")}
                >
                    OK
                </div>

            </div>

            </div>
        )
    }

    return (
        <div className='App'>     
            <MenuAlto {...props} bottoneHome={true}/>
            <Intestazione {...props}/>
            <TitoloPagina {...props}/>

            <div 
                style={{
                    minHeight:props.altezzaContenitoreHome,
                    backgroundColor: "#F5F5F5",
                    marginTop: 20,
                    marginBottom: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    alignItems: "center",
                    display:"flex",
                    flexDirection: "column",
                    cursor:"pointer",
                    fontSize:16,
                    lineHeight:1.4                
                }}
            >
                Compila la form qui sotto e potrai provare l'applicazione gratis per 2 settimane.

                <br/><br/>
                <span style={{fontStyle:'italic', fontSize:14}}>
                    Non daremo mai i tuoi dati ad altri
                </span>
                <br/>
                <div style={{                        
                    border:"1px solid #202020", 
                    textAlign:'left', 
                    paddingLeft:10, 
                    width:'90%',
                    backgroundColor:'#FFFFFF', 
                    boxShadow: "1.5px 1.5px #B0B0B0"}}>
                    <span style={{fontSize:13, fontWeight:"bold", color:"#006fe0"}}>Dati necessari</span><br/>
                    <ElementoInput {...props} etichetta="Indirizzo email:" valore={email} callback={(v) => setEmail(v)}/>
                    <div style={{display:"flex",flexDirection:"row", marginTop:10, fontStyle:'italic'}}>
                        Consenso al trattamento dei dati:
                    </div>
                    <div style={{display:"flex",flexDirection:"row", marginTop:5, marginBottom:10}}>
                        <div>
                            <input type='checkbox' checked={flagConsenso || false} onChange={e => { setFlagEvidenziaRichiestaConsenso(false); setFlagConsenso(e.target.checked)}}/>
                        </div>
                        <div style={{width:10}}/>
                        <div style={{flex:1, backgroundColor:flagEvidenziaRichiestaConsenso?"#FFC0C0":""}}>
                            Dichiaro di avere legalmente accesso all'indirizzo email indicato; accetto che tutti i dati inseriti in questa pagina e tutti i dati che inserirò nell'applicazione vengano memorizzati da {props.produttore.ragioneSociale} e mantenuti per tutta la durata della prova del servizio e dell'eventuale successivo acquisto.
                        </div>
                        <div style={{width:10}}/>
                    </div>
                </div>
                
                <div style={{                        
                    border:"1px solid #202020", 
                    textAlign:'left',
                    marginTop:20, 
                    paddingLeft:10, 
                    width:'90%',
                    backgroundColor:'#FFFFFF', 
                    boxShadow: "1.5px 1.5px #B0B0B0"}}>
                    
                    <span style={{fontSize:13, fontWeight:"bold", color:"#01a500"}}>
                        Dati facoltativi
                        {props.dimensioniApp.flagPaginaOrizzontale ? " " : <br/>}
                        (verranno inseriti automaticamente nel tuo ambiente)
                    </span><br/>
                    <FormResponsive 
                        stile={{border:"1px solid #C0C0C0",fontSize:16,backgroundColor:"#F0F0F0"}}
                        elencoCampi={elencoCampi}
                        valoriCampiForm={valoriCampiForm}
                        setValoriCampiForm={setValoriCampiForm}
                    />

                    <div style={{display:"flex",flexDirection:"row", marginTop:10, marginBottom:10, marginRight:15}}>
                        {props.dimensioniApp.flagPaginaOrizzontale ? "Data di chiusura esercizio (solitamente è il 31 dicembre):"
                        : "Chiusura esercizio:" }
                        <div style={{width:10}}/>
                        <div>
                            <select style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0"}} onChange={e => setGiornoChiusuraEsercizio(e.target.value)}>
                                {giorni.map((giorno,indice)=>
                                    giorno==giornoChiusuraEsercizio ? 
                                        <option key={indice} selected value={giorno}>{giorno}</option>
                                    :
                                        <option key={indice} value={giorno}>{giorno}</option>
                                    )}                                    
                            </select>
                        </div>
                        <div style={{width:10}}/>
                        <div>
                            <select style={{border:"1px solid #C0C0C0", fontSize:16, backgroundColor:"#F0F0F0"}} onChange={e => setMeseChiusuraEsercizio(e.target.value)}>
                                {mesi.map((mese,indice)=>
                                    indice==meseChiusuraEsercizio ?
                                        <option key={indice} selected value={indice}>{mese}</option>
                                    :
                                        <option key={indice} value={indice}>{mese}</option>
                                    )}
                            </select>
                        </div>
                        <div style={{flex:1}}></div>
                    </div>
                </div>

                <div 
                    style={{
                        backgroundColor:"#006fe0", 
                        color:'#f0f0f0',                         
                        marginTop:20, 
                        marginBottom:10, 
                        paddingTop:8, 
                        paddingBottom:8, 
                        paddingLeft:20, 
                        paddingRight:20, 
                        cursor:'pointer'
                    }}
                    onClick={() => inviaDati()}
                >
                    Conferma
                </div>

            </div>
            
            <FondoPagina {...props}/>
        </div>
    )        
}

export default ProvaGratis;